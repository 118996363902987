/* @flow */
import * as React from 'react';
import { Fragment } from 'react'
import Helmet from 'react-helmet'

import cx from 'classnames'
import styles from '../layouts/styles.module.css'

import Nav from '../layouts/nav';
import Footer from '../layouts/footer';

type Props = {
  children: React.Node,
  path?: string,
}

const Layout = ({ children, path }: Props): React.Node => (
<Fragment>
  <Helmet>
    <title>SECOMAX - Intelligent Systems</title>
    <meta name='description' content='Next-gen intelligent systems for modern retail' />
    <meta charSet='utf-8' />
    <link rel='canonical' href='https://kemene.com' />
  </Helmet>
  <Nav index={path === "/"}/>
  {children}
  <Footer />
</Fragment>
  )


type SquareProps = { children?: React.Node, className?: any }

export const SquareContainer = ({ children, className }: SquareProps): React.Node => (
  <div className={cx(styles.square_container, className)}>{children}</div>
)

export const SquareRow = ({ children } : { children?: React.Node }): React.Node => (
  <div className={cx(styles.square_row)}>{children}</div>
)

export const SquareColumn = ({ children } : { children?: React.Node }): React.Node => (
  <div className={cx(styles.square_column)}>{children}</div>
)


type BoxProps = { children?: React.Node, className?: any, small?: boolean, mid?: boolean, id?: string }
export const SquareBox = ({ children, className, small, mid, ...rest }: BoxProps): React.Node => (
  <div className={cx(styles.square_box, small && styles.square_box_4, mid && styles.square_box_3, className)} {...rest}><div>{children}</div></div>
)


export default Layout
