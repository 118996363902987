/* @flow */
import * as React from 'react';
import { useState , useEffect, useCallback } from 'react';
import { StaticImage } from "gatsby-plugin-image"

import { Fragment } from 'react'
import Helmet from 'react-helmet'

import { Link, IntlContextConsumer, changeLocale, FormattedMessage } from "gatsby-plugin-intl"

import cx from 'classnames'
import styles from '../layouts/styles.module.css'


const logo = '../images/secomax_logo_wide.png'


const languageName = {
  en: "English",
  de: "Deutsch",
}

type Props = {
  index?: boolean,
}
const Nav = ({ index } : Props ): React.Node => {

  const [menuOpen, setMenu] = useState(false);
  const closeMenu = () => setMenu(false);
  const toggleMenu = useCallback(() => setMenu(!menuOpen), [setMenu, menuOpen])

  return (
    <section className={cx({[styles.NavSection]: true, [styles.NavOpen]: menuOpen })}>
      <IntlContextConsumer>
        {({ languages, language }) => (<nav>
          <Link to='/'>
            <StaticImage objectFit="contain" width={200} data-loading='eager' alt='KEMENÉ' src={logo} />
          </Link>

        </nav>)}
      </IntlContextConsumer>
    </section>
  )
}
export default Nav
