/* @flow */
import * as React from 'react';
import cx from 'classnames'
import { Link } from "gatsby"

import styles from './styles.module.css'


const Footer = (): React.Node => (
  <section className={ cx(styles.section_footer) }>
    <div>
      <p>SECOMAX is a project of <Link to="https://kemene.com">KEMENE CONSULTING</Link>.</p>
      <p>All trademarks, logos and brand names are the property of their respective owners. All company, product and service names used in this website are for identification purposes only. Use of these names, trademarks and brands does not imply endorsement. Liability limited by a scheme approved under Professional Standards Legislation.</p>
    </div>
  </section>
)

export default Footer
